/* eslint-disable no-empty */
/* eslint-disable class-methods-use-this */
import { iterUnpack } from './APIClient';
import { ANALYTICS_NAME } from '../components/brand_detail/Constants';
// eslint-disable-next-line import/no-cycle
import { UserResource } from './RESTClient';

// Event names originating from Brand Profiles
const brandProfileEvents = [
  ANALYTICS_NAME.BRAND_PROFILE_ARTICLE_CLICKED,
  ANALYTICS_NAME.BRAND_FIRM_USING_BRAND_CLICKED,
  ANALYTICS_NAME.BRAND_SHOW_MORE_PRODUCTS_CLICKED,
  ANALYTICS_NAME.BRAND_PROJECT_CARD_CLICKED,
  ANALYTICS_NAME.BRAND_PROJECT_TYPE_FILTER_CHANGED,
  ANALYTICS_NAME.BRAND_SHOW_MORE_PLACEMENTS_CLICKED,
  ANALYTICS_NAME.BRAND_CONTACT_MODAL_OPENED,
  ANALYTICS_NAME.BRAND_SIMILAR_BRAND_CLICKED,
  ANALYTICS_NAME.BRAND_TELEPHONE_BUTTON_CLICKED,
  ANALYTICS_NAME.BRAND_MESSAGE_SUBMITTED,
];

export const globalSegmentOptions = window.globalSegmentOptions || {};

export default class SegmentClient {
  /**
   * initilize with props to send them along every track request
   */
  constructor(props) {
    this.props = props || {};
  }

  identify({ traits = {}, force = false, callback = () => {} }) {
    if (!window.analytics) {
      const err = new Error('window.analytics is undefined');
      Raven.captureException(err);
      callback(err);
      return;
    }

    const userResource = new UserResource();
    userResource.me()
      .then((user) => {
        // Segment Limitation: only allow god and professional users to be identified
        if (force || userResource.isGod() || userResource.isProfessional()
            || userResource.isPendingProfessional()) {
          window.analytics.identify(user.data.id, {
            ...user.data.traits,
            ...traits,
          }, globalSegmentOptions, callback);
        }
      }).catch((err) => {
        callback(err);
      });
  }

  page(properties = {}) {
    if (!window.analytics) {
      // eslint-disable-next-line no-undef
      Raven.captureException('window.analytics is undefined');
      return;
    }

    const userResource = new UserResource();
    userResource.me()
      .then((user) => {
        window.analytics.page({
          ...properties,
          user_type: user.data ? user.data.user_progress : null,
        }, globalSegmentOptions);
      }, () => {
        window.analytics.page(properties, globalSegmentOptions);
      }).catch(() => {
        window.analytics.page(properties, globalSegmentOptions);
      });
  }

  track(eventName = '', properties = {}, integrations = {}) {
    if (!window.analytics) {
      // eslint-disable-next-line no-undef
      Raven.captureException('window.analytics is undefined');
      return;
    }

    if (!eventName) {
      return;
    }

    const trackProperties = {
      ...this.props,
      ...properties,
    };

    // For Brand Profile events, add brand data to properties
    if (brandProfileEvents.includes(eventName)) {
      const brand = iterUnpack(JSON.parse(window.props)).allBrands[0];
      if (!brand) return;

      // Add brand data to properties
      trackProperties.brand_id = brand.djangoId;
      trackProperties.brand_name = brand.name;
      trackProperties.brand_slug = brand.slug;
    }

    let options = {};
    try {
      options = { ...globalSegmentOptions };

      if (Object.keys(integrations).length) {
        options.integrations = integrations;
      }
    } catch (_) {}

    window.analytics.track(eventName, trackProperties, options);
  }
}
