export const ANALYTICS_NAME = {
  BRANDS_EXPOSURE: 'brands exposure',
};

export const BRAND_EXPOSURE_SECTIONS = {
  BRANDS_LEADERBOARD: 'BRANDS_LEADERBOARD',
  BRANDS_LEADERBOARD_BRAND_CLICK: 'BRANDS_LEADERBOARD_BRAND_CLICK',
  HOMEPAGE: 'HOMEPAGE',
  HOMEPAGE_CLICK: 'HOMEPAGE_CLICK',
};

export const BRAND_EXPOSURE_ACTIONS = {
  IMPRESSION: 'IMPRESSION',
  FEATURED_IMPRESSION: 'FEATURED_IMPRESSION',
  BRAND_CLICK: 'BRAND_CLICK',
  PRODUCT_CLICK: 'PRODUCT_CLICK',
};

export const PROJECT_TYPE_ROOT_SLUG = 'project-type';
export const SECTIONS = {
  BRANDS: 'Brands',
};
export const BRANDS_COUNT = 5;
export const PSEUDOTIERS_NAV_ID = 'pt-nav';
export const RESULTS_CONTAINER_ID = 'results-container';

export const NO_PROJECT_TYPE = 'NO_PROJECT_TYPE';

export const CALL_SOURCES = {
  PROJECT_DETAILS: 'PROJECT_DETAILS',
  IMAGE_SEARCH: 'IMAGE_SEARCH',
  IDEA_PAGE: 'IDEA_PAGE',
  COLLECTIONS: 'COLLECTIONS',
};
