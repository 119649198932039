import React from 'react';
import PropTypes from 'prop-types';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import { ConditionalWrapper } from '../../services/utils';

const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';
const lowerSize = {
  [SMALL]: SMALL,
  [MEDIUM]: SMALL,
  [LARGE]: MEDIUM,
};
const paddings = {
  [SMALL]: 'p-s',
  [MEDIUM]: 'p-base',
  [LARGE]: 'p-l',
};
const fontSizes = {
  [SMALL]: 'fs-s',
  [MEDIUM]: 'fs-base',
  [LARGE]: 'fs-l',
};

const getPlaceholder = (obj) => {
  if (obj === null) {
    return <div className="placeholder">&nbsp;</div>;
  }
  return null;
};

const Card = ({
  isHero,
  size,
  height,
  link,
  src,
  subSrc,
  classNames,
  category,
  categoryClassNames,
  heading,
  headingClassNames,
  subheading,
  subheadingClassNames,
  extra,
  extraClassNames,
  isNew,
  isTrending,
  isRising,
  isPinned,
  onClick,
}) => (
  <div className={`ticket hover-img-opacity-9 ${isHero ? 'p-r' : ''} ${classNames}`}>
    {/* Image */}
    <ConditionalWrapper
      condition={Boolean(link)}
      wrapper={children => <a href={link} onClick={onClick}>{children}</a>}
    >
      <div className={`wrapper image ${height ? `h-${height}` : ''} bg-gunmetal-300 p-r`}>
        {src && <img className="full-height full-width cover lazyload" src={src} data-src={src} alt={heading} />}
        {isPinned && <i className="material-icons pinned" title="Pinned">bookmark</i>}
        {isNew && <div className="eyebrow new">New</div>}
        {isTrending && <div className="eyebrow trending">Trending</div>}
        {isRising && <div className="eyebrow rising">Popular</div>}
      </div>
    </ConditionalWrapper>
    {/* Info */}
    {/* {(subSrc || category || heading || subheading || extra) && */}
    <div className={`grid-x info ${isHero ? `info-overlay p-a ${paddings[size]}` : 'mt-s'} ${isHero && src ? 'back-gradient' : ''}`}>
      {/* Sub Image */}
      {subSrc ? (
        <div className="cell shrink image w-50 h-50 bg-gunmetal-300 mr-xs">
          <img className="full-height full-width cover lazyload" src={subSrc} data-src={subSrc} alt="" />
        </div>
      ) : ''}
      <div className="cell auto">
        {/* Category */}
        <h6 className={`m-0 ${categoryClassNames}`}>{category || getPlaceholder(category)}</h6>
        {/* Heading */}
        <a
          href={link}
          className={`block ${fontSizes[size]} ${headingClassNames}`}
          onClick={onClick}
        >
          {heading || getPlaceholder(heading)}
        </a>
        {/* Subheading */}
        <h5 className={`${subheadingClassNames} ${fontSizes[lowerSize[size]]}`} title={subheading}>
          {subheading || getPlaceholder(subheading)}
        </h5>
        {/* Extra */}
        <small className={`block ${extraClassNames}`}>{extra || getPlaceholder(extra)}</small>
      </div>
    </div>
  </div>
);

Card.propTypes = {
  isHero: PropTypes.bool,
  size: PropTypes.oneOf([LARGE, MEDIUM, SMALL]),
  height: PropTypes.number,
  link: PropTypes.string,
  src: PropTypes.string,
  subSrc: PropTypes.string,
  classNames: PropTypes.string,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  categoryClassNames: PropTypes.string,
  heading: PropTypes.string,
  headingClassNames: PropTypes.string,
  subheading: PropTypes.string,
  subheadingClassNames: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extraClassNames: PropTypes.string,
  isNew: PropTypes.bool,
  isTrending: PropTypes.bool,
  isRising: PropTypes.bool,
  isPinned: PropTypes.bool,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  isHero: false,
  size: SMALL,
  height: undefined,
  link: undefined,
  src: '',
  subSrc: undefined,
  classNames: '',
  category: undefined,
  categoryClassNames: '',
  heading: undefined,
  headingClassNames: '',
  subheading: undefined,
  subheadingClassNames: '',
  extra: undefined,
  extraClassNames: '',
  isNew: false,
  isTrending: false,
  isRising: false,
  isPinned: false,
  onClick: () => {},
};

export default Card;
