// HTTP Methods
export const GET = 'GET';
export const HEAD = 'HEAD';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const CONNECT = 'CONNECT';
export const OPTIONS = 'OPTIONS';
export const TRACE = 'TRACE';
export const PATCH = 'PATCH';
export const HTTP_METHODS = {
  [GET]: GET,
  [HEAD]: HEAD,
  [POST]: POST,
  [PUT]: PUT,
  [DELETE]: DELETE,
  [CONNECT]: CONNECT,
  [OPTIONS]: OPTIONS,
  [TRACE]: TRACE,
  [PATCH]: PATCH,
};

// Environments
export const LOCAL = 'local';
export const DEVELOPMENT = 'dev'; // a.k.a Staging
export const PRODUCTION = 'prod';
export const IS_LOCAL = window.ENV === LOCAL;
export const IS_DEV = window.ENV === DEVELOPMENT;
export const IS_PROD = window.ENV === PRODUCTION;

export const GLOBAL_HEADER_ID = 'Header';

export const PUBLISHED = 'published';
export const DRAFT = 'draft';
export const CLOSED = 'closed';

export const { RECAPTCHA_V3_PUBLIC_KEY } = window;
export const RECAPTCHA_V3_TIMEOUT = 3000; // ms

// Actions may only contain alphanumeric characters and slashes, and must not be user-specific.
// https://developers.google.com/recaptcha/docs/v3#actions
export const RECAPTCHA_ACTIONS = {
  PHOTO_CREDIT_FORM_ADD_FIRM: 'photocredit/createfirm',
};

export const ATTRIBUTIONS_MODEL = {
  MEDIA_ATTRIBUTION: 'MEDIA_ATTRIBUTION',
  BRAND_ATTRIBUTION: 'BRAND_ATTRIBUTION',
};
export const ATTRIBUTIONS_GLOBAL_ID = {
  MEDIA_ATTRIBUTION: 'media.mediaitemattribution',
  BRAND_ATTRIBUTION: 'brands.brandattribution',
};

/*
- Based on Foundation 6
- Reference https://foundation.zurb.com/sites/docs/media-queries.html
- Usage within styled-components:
  const CustomComponent = styled.div`
    @media screen and (max-width: ${MEDIA_QUERIES.small}) {}

    @media screen and (min-width: ${MEDIA_QUERIES.medium}) {}

    @media screen and (min-width: ${MEDIA_QUERIES.mediumOnly[0]})
      and (max-width: ${MEDIA_QUERIES.mediumOnly[1]}) {}

    @media screen and (min-width: ${MEDIA_QUERIES.large}) {}

    @media screen and (min-width: ${MEDIA_QUERIES.largeOnly[0]})
      and (max-width: ${MEDIA_QUERIES.largeOnly[1]}) {}
`;
*/
export const MEDIA_QUERIES = {
  // Small only
  small: '39.9375em',
  // Medium and up
  medium: '40em',
  // Medium only
  mediumOnly: ['40em', '63.9375em'],
  // Large and up
  large: '64em',
  // Large only
  largeOnly: ['64em', '74.9375em'],
};

export const MEDIA_SIZES = {
  X_LARGE: {
    w: 2000,
    q: 60,
    auto: 'format,compress',
    cs: 'strip',
    fit: 'max',
  },
  LARGE: {
    w: 1680,
    q: 60,
    auto: 'format,compress',
    cs: 'strip',
    fit: 'max',
  },
  MEDIUM: {
    w: 1080,
    q: 60,
    auto: 'format,compress',
    cs: 'strip',
    fit: 'max',
  },
  SMALL: {
    w: 520,
    q: 60,
    auto: 'format,compress',
    cs: 'strip',
    fit: 'max',
  },
  THUMBNAIL: {
    w: 200,
    q: 60,
    auto: 'format,compress',
    cs: 'strip',
    fit: 'max',
  },
};

export const SRC_SET_METHODS = {
  WIDTH: 'w',
  RESOLUTION: 'X',
};

// Brand Tiers
// Basic =>  alias for not_paying
export const BRAND_TIER_FREE_ID = 'not_paying';
// Premium => alias for brand_profile
export const BRAND_TIER_BRAND_PROFILE = 'brand_profile';

export const ANALYTICS = {
  FIND_SIMILAR_ACTION: 'similar_image_search',
  SAVE_IMAGE_ACTION: 'image_saved',
};

export const PAGES_TITLES = {
  FIND_SIMILAR: 'Architizer: Architectural Projects and Design Interiors',
  SAVE_IMAGE: 'Save Image',
};

export const FIRM_TYPES = {
  ARCHITECTURE_DESIGN_FIRM: 'Architecture / Design Firm', // <== default
  REAL_ESTATE_DEVELOPMENT: 'Real Estate Development',
  PHOTOGRAPHY_STUDIO: 'Photography Studio',
  MARKETING_AGENCY: 'Marketing Agency',
  // ...
};

// Ads & Google Ad Manager (GPT)
export const ARCHITIZER_NETWORK_CODE = '4016414';
export const AD_UNITS = {
  DEV_TEST: {
    name: 'devTest',
    size: [300, 250],
  },
  Feed_BTF: {
    name: 'FeedBTF',
    size: [728, 90],
  },
  Brands_Browse_Repeat: {
    name: 'BrandsBrowseRepeat',
    size: [728, 90],
  },
  FEED_ATF: {
    name: 'FeedATF',
    size: [728, 90],
  },
  FEED_REPEAT: {
    name: 'FeedRepeat',
    size: [728, 90],
  },
  FEED_RIGHT_BAR_BOTTOM: {
    name: 'FeedRightBarBottom',
    size: [300, 250],
  },
  FEED_RIGHT_BAR_TOP: {
    name: 'FeedRightBarTop',
    size: [300, 250],
  },
  PROJECT_PAGE_CUSTOM_1: {
    name: 'ProjectPageCustom1',
    size: [300, 250],
  },
  PROJECT_PAGE_CUSTOM_2: {
    name: 'ProjectPageCustom2',
    size: [300, 250],
  },
  PROJECT_PAGE: {
    name: 'ProjectPage',
    size: [300, 250],
  },
  PRODUCTS_BROWSE_REPEAT: {
    name: 'ProductsBrowseRepeat',
    size: [728, 90],
  },
  PROJECT_BROWSE_ATF: {
    name: 'ProjectBrowseATF',
    size: [728, 90],
  },
  PROJECTS_BROWSE_REPEAT: {
    name: 'ProjectsBrowseRepeat',
    size: [728, 90],
  },
  FIRM_PAGE_MASTHEAD: {
    name: 'FirmPageMasthead',
    size: [[728, 90], [970, 90]],
  },
  PROJECT_PAGE_MASTHEAD: {
    name: 'ProjectPageMasthead',
    size: [[728, 90], [970, 90]],
  },
  SINGLE_POST_MASTHEAD: {
    name: 'SinglePostMasthead',
    size: [[728, 90], [970, 90]],
  },
  SINGLE_POST: {
    name: 'SinglePost',
    size: [[300, 250], [300, 600], [300, 1050]],
  },
  SINGLE_POST_BTF: {
    name: 'SinglePostBTF',
    size: [[300, 250], [300, 600], [300, 1050]],
  },
  SINGLE_POST_ATF: {
    name: 'SinglePostATF',
    size: [[300, 250], [300, 600], [300, 1050]],
  },
  SINGLE_POST_TEXT: {
    name: 'SinglePostText',
    size: [1, 1],
  },
  SINGLE_POST_BILLBOARD_BTF: {
    name: 'SinglePostBillboardBTF',
    size: [[728, 90], [970, 90], [970, 250]],
  },
  IMAGE_MODAL_PRODUCT: {
    name: 'imageModalProduct',
    size: [300, 250],
  },
  IMAGE_MODAL_PROJECT: {
    name: 'imageModalProject',
    size: [300, 250],
  },
  IMAGE_MODAL_POST: {
    name: 'imageModalPost',
    size: [300, 250],
  },
  IMAGE_MODAL: {
    name: 'imageModal',
    size: [300, 250],
  },
  IMAGE_MODAL_COLLECTION: {
    name: 'imageModalCollection',
    size: [300, 250],
  },
  HOMEPAGE_RIGHT_RAIL_STICKY_MEDIUM_BANNER: {
    name: 'homepageRightRailStickyMediumBanner',
    size: [300, 250],
  },
  HOMEPAGE_DESKTOP_BETWEEN_PAGES_LEADERBOARD: {
    name: 'homepageDesktopBetweenPagesLeaderboard',
    size: [728, 90],
  },
  HOMEPAGE_MOBILE_BETWEEN_PAGES_LEADERBOARD: {
    name: 'homepageMobileBetweenPagesLeaderboard',
    size: [320, 50],
  },
  HOMEPAGE_RIGHT_RAIL_AFTER_NEWSLETTER_MEDIUM_BANNER: {
    name: 'homepageRightRailAfterNewsletterMediumBanner',
    size: [300, 250],
  },
  HOMEPAGE_RIGHT_RAIL_AFTER_TRENDING_MEDIUM_BANNER: {
    name: 'homepageRightRailAfterTrendingMediumBanner',
    size: [300, 250],
  },
  HOMEPAGE_DESKTOP_TOP_BILLBOARD: {
    name: 'homepageTopBillboard',
    size: [970, 250],
  },
  HOMEPAGE_MOBILE_TOP_LARGE_BANNER: {
    name: 'homepageMobileTopLargeBanner',
    size: [320, 100],
  },
};
// AdPush Ad Units
export const AP_AD_UNITS = {
  IMAGE_MODAL_PROJECT: IS_PROD ? '84a7b6dc-aa57-4135-9a2f-a5d96c5785d8' : '9210d1dc-099b-428c-ae16-d0c1b2db9b3b',
  IMAGE_MODAL_COLLECTION: IS_PROD ? 'e70909d0-fdab-4d52-b60c-e61fd68d3676' : '26456ed1-4c0d-4b73-b870-254248ecd06f',
  HOMEPAGE_DESKTOP_AFTER_PRODUCTS_LEADERBOARD: IS_PROD ? 'd014b74c-66c0-443c-8b6c-1c129ee4fd70' : '76ed6547-081a-4801-823e-453433f17ba6',
  HOMEPAGE_DESKTOP_LARGE_LEADERBOARD: IS_PROD ? '8f54e282-b020-44d2-9898-5633820ad41f' : '2ca0fdf2-6d65-4cbe-80c2-df17e6eeb2df',
  HOMEPAGE_IN_CONTENT_SMALL_SQUARE: IS_PROD ? '3dba5796-6041-426e-8160-f173f3dfaecb' : 'a652c1b0-3c71-4fa3-a2ca-2853f2de1ae0',
  HOMEPAGE_MOBILE_AFTER_PRODUCTS_LEADERBOARD: IS_PROD ? '1ea07250-b01e-4ec7-b198-dc34cc093d73' : 'dcc73a11-552b-48b1-b13e-b4b4825ccec9',
  HOMEPAGE_MOBILE_LEADERBOARD: IS_PROD ? 'b2d48782-96d6-4dea-8fd5-0ea650ca7f29' : '2f0f71c1-50f1-4d75-b83d-d888f3c84e99',
  HOMEPAGE_RIGHT_RAIL_AFTER_NEWSLETTER_MEDIUM_BANNER: IS_PROD ? '318d98c9-0996-45fa-95df-b71936097abe' : 'f1160906-cb47-4147-8c78-44080ee40f57',
  HOMEPAGE_RIGHT_RAIL_STICKY_HALFPAGE: IS_PROD ? '8fb17e91-897c-454f-8eeb-0cae62ed5ec7' : 'a1d21783-32dd-4265-b030-9bf13255fa24',
  HOMEPAGE_RIGHT_RAIL_STICKY_MEDIUM_BANNER: IS_PROD ? '126e85c9-2a93-4e80-8f79-57c3d9514a77' : '7a938102-f353-42f3-a5c5-97fac8509e51',
};

export const AD_TARGETS = {
  MEMBER_TYPE: 'member_type',
};

export const AD_MIN_REFRESH_TIME = 1000; // in ms

export const AD_CLIENTS = {
  GAM: 'GOOGLE_AD_MANAGER',
  AP: 'AD_PUSHUP',
};
