import 'url-polyfill';
import 'whatwg-fetch';
import { oneLine } from 'common-tags';

import {
  getCSRFToken,
} from './clientsUtils';
import {
  HTTP_METHODS,
} from './constants';

// Constants for authentication
const CSRF_TOKEN = getCSRFToken();
const API_ENDPOINT = '/api/v3.0/graphql';
export const AWS_S3_BUCKET_NAME = 'architizercdn';

// Default values for thumbnails and placeholders
const designKitAsset = url => `//design-kit.arc.ht/assets/${url}`;
export const defaultUserAvatarUrl = designKitAsset('user.svg');
export const defaultLogoImageUrl = designKitAsset('brand.svg');
export const defaultProjectImageUrl = designKitAsset('project.svg');
export const defaultArticleImageUrl = designKitAsset('article.svg');
export const defaultFirmImageUrl = designKitAsset('firm.svg');
export const defaultProductImageUrl = designKitAsset('product.png');
export const placeholder43Url = designKitAsset('placeholder-4:3.png');
export const arrowLeft = designKitAsset('arrow-left.svg');
export const arrowRight = designKitAsset('arrow-right.svg');
export const architizerLogoUrl = {
  svg: designKitAsset('logo.svg'),
  white: {
    sm: designKitAsset('logos/architizer-white-sm.png'),
    lg: designKitAsset('logos/architizer-white-lg.png'),
  },
  black: {
    xs: designKitAsset('logos/architizer-tiny.png'),
    sm: designKitAsset('logos/architizer-sm.png'),
    md: designKitAsset('logos/architizer-md.png'),
    lg: designKitAsset('logos/architizer-lg.png'),
  },
};

/* eslint-disable no-param-reassign */
export const iterUnpack = (object) => {
  /*
    * The behaviour of iterUnpack will take a GraphQL JSON Response key like this:
    *
    *    projects: {
    *      edges: [
    *        {
    *          node: {
    *            ...data
    *          }
    *        }
    *      ],
    *      pageInfo: {
    *        ...metaData
    *      }
    *    }
    *
    * and turns it into a flattened array like so, moving pageInfo into a
    * 'metadata' key of the returned data:
    *
    *    projects: [
    *      { ...data },
    *      { ...data }
    *    ],
    *    metadata: {
    *      projects: {
    *        ...metaData
    *      }
    *    }
    */

  // A small utility to unmap { edges: { node: {} } }
  const unpackEdges = edges => edges.map(({ node, cursor }) => {
    if (cursor) node.cursor = cursor;
    return node;
  });
  const metadata = {};

  const populateMetadata = (obj) => {
    Object.keys(obj).map((property) => {
      if (typeof obj[property] === 'object' && obj[property]) {
        if (Object.keys(obj[property]).indexOf('pageInfo') >= 0) {
          metadata[property] = obj[property].pageInfo;
        }
        populateMetadata(obj[property]);
      }
      return obj;
    });
  };

  const flatten = (obj) => {
    Object.keys(obj).map((property) => {
      if (typeof obj[property] === 'object' && obj[property]) {
        if (Object.keys(obj[property]).indexOf('edges') >= 0) {
          obj[property] = unpackEdges(obj[property].edges);
        }
        flatten(obj[property]);
      }
      return obj;
    });
    return obj;
  };

  populateMetadata(object);
  const flatObjectWithMetadata = Object.assign({ metadata }, flatten(object));

  return flatObjectWithMetadata;
};
/* eslint-enable no-param-reassign */

// Utility Functions

/*
 * dedupe returns an array that removes all duplicates from an API response.
 * Ultimately, check your GraphQL query and find where there should be a .distinct()
 * written into the server.
 * @param {Array} arrayOfObjects: an array of JSON-serializable objects.
 */
export const dedupe = (arrayOfObjects) => {
  const unique = new Set(arrayOfObjects.map(obj => JSON.stringify(obj)));
  return Array.from(unique).map(jsonString => JSON.parse(jsonString));
};

/* getProductresponseStatus returns a human-readable phrase from
 * API-returned single-letter keys.
 * @param {String} marking: the marking from the api (N, F, C, or R)
 */
export const getProductResponseStatus = (marking) => {
  const statusMap = {
    N: 'In Review',
    F: 'Shortlisted',
  };
  return statusMap[marking];
};

/**
 * Returns an imgIX URL string with search parameters set.
 * @param {String} imgixUrl: Raw imgIX URL string of the media resource
 * @param {Object.<String, String|Number>} params: imgIX API parameters, keys must match imgIX's.
 * API Parameters: https://docs.imgix.com/apis/url
 */
export function prepareImgixParams(imgixUrl, params = {}) {
  try {
    const url = new URL(imgixUrl);

    const defaultParams = {
      fit: 'crop',
    };
    const searchParams = {
      ...defaultParams,
      ...params,
    };

    Object.entries(searchParams).forEach((param) => {
      if (param[1]) {
        url.searchParams.set(param[0], param[1]);
      }
    });

    return url.toString();
  } catch (err) {
    Raven.captureException(err);
    return imgixUrl || '';
  }
}

/*
 * APIClient returns a Promise to resolve a query against the
 * Architizer GraphQL endpoint.
 * @param {String} query: the query string in GraphQL
 * @param {Bool} unpack: unpack {edges: {node}} syntax into
 *    flat arrays.
 */
const APIClient = (
  query,
  unpack = false,
  body = new FormData(),
  method = HTTP_METHODS.POST,
) => {
  const APIUrl = new URL(window.location.origin + API_ENDPOINT);
  const headers = {
    'X-CSRFToken': CSRF_TOKEN,
    Accept: 'application/json',
  };
  if (query) {
    APIUrl.searchParams.append('query', oneLine`${query}`);
  } else {
    // If query is blank, assume all information is in 'body' as JSON
    headers['Content-Type'] = 'application/json';
  }

  const APIRequest = new Request(APIUrl, {
    headers,
    method,
    credentials: 'same-origin',
    body: [HTTP_METHODS.GET, HTTP_METHODS.HEAD].includes(method) ? null : body,
  });

  let out;
  if (!unpack) {
    out = fetch(APIRequest);
  } else {
    out = fetch(APIRequest)
      .then((response) => {
        Raven.captureBreadcrumb({
          message: 'Parse JSON from GraphQL API',
          category: 'graphql',
          data: {
            APIUrl,
            query,
            contentType: headers['Content-Type'],
            csrf_token: CSRF_TOKEN,
          },
        });
        return response.json();
      })
      .then((response) => {
        // Don't throw if any errors happen; instead, display them
        if ('errors' in response) {
          response.errors.map((error) => { throw new Error(error.message); });
        }
        return response.data;
      })
      .then(data => iterUnpack(data));
  }

  return out;
};

export default APIClient;
