/* eslint-disable no-empty */
/* eslint-disable class-methods-use-this */

export default class GA4Client {
  /**
   * initilize with props to send them along every track request
   */
  constructor(props) {
    this.props = props || {};
  }

  page(properties = {}) {
    if (!window.gtag) {
      // eslint-disable-next-line no-undef
      Raven.captureException('window.gtag is undefined');
      return;
    }

    window.gtag('event', 'page_view', properties);
  }
}
