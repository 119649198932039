import 'url-polyfill';
import 'whatwg-fetch';
import Cookies from 'js-cookie';
import intersection from 'lodash/intersection';

import APIClient from './APIClient';
// eslint-disable-next-line import/no-cycle
import {
  getCSRFToken,
} from './clientsUtils';

import {
  BRAND_TIER_FREE_ID,
  BRAND_TIER_BRAND_PROFILE,
  ATTRIBUTIONS_GLOBAL_ID,
} from './constants';
import {
  MEDIA_CREDIT_TARGET,
} from '../components/media_credit/Constants';

const queryString = require('query-string');

const API_PREFIX = '/api/v3.0';
const CSRF_TOKEN = getCSRFToken();
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRFToken': CSRF_TOKEN,
};
const credentials = 'same-origin'; // This MUST be included in each fetch request that needs to be authenticated since MSBrowsers doesn't send cookies by default
const { USER_PROGRESSES } = window;

// elements be lower case
const SEARCH_ENGINES = [
  'altavista',
  'ask',
  'google',
  'live',
  'lycos',
  'msn',
  'yahoo',
  'bing',
  'aol',
  'yandex',
  'archive',
  'baidu',
  'wolframalpha',
  'duckduckgo',
];

// elements be lower case
const BOTS_USER_AGENT = [
  '/bot',
  'googlebot',
  'crawler',
  'spider',
  'robot',
  'crawling/i',
  'naver',
];

export class UserResource {
  constructor() {
    this.baseEndPoint = `${API_PREFIX}/users`;
  }

  list(query, otherParams = []) {
    const endPoint = new URL(`${window.location.origin}${this.baseEndPoint}`);

    otherParams.forEach(({ key, value }) => {
      endPoint.searchParams.append(key, value);
    });
    endPoint.searchParams.append('q', query);
    return fetch(endPoint, {
      credentials,
      headers,
    })
      .then(response => response.json());
  }

  me() {
    if (UserResource.user) {
      return new Promise(resolve => resolve(UserResource.user));
    } if (UserResource.userRejectedError) {
      return new Promise((resolve, reject) => reject(UserResource.userRejectedError));
    }

    if (!UserResource.pendingUserRequests.length) {
      const endPoint = `${this.baseEndPoint}/me`;
      fetch(endPoint, {
        credentials,
        headers,
      })
        .then((response) => {
          response.json().then((data) => {
            if (data.success) {
              UserResource.user = data;
              UserResource.pendingUserRequests.forEach(({ resolve }) => resolve(data));
            } else {
              UserResource.userRejectedError = data;
              UserResource.pendingUserRequests.forEach(({ reject }) => reject(data));
            }
            UserResource.pendingUserRequests = [];
          });
        }, (error) => {
          UserResource.userRejectedError = error;
          UserResource.pendingUserRequests.forEach(({ reject }) => reject(error));
          UserResource.pendingUserRequests = [];
          Raven.captureException(error);
        });
    }
    // eslint-disable-next-line max-len
    return new Promise((resolve, reject) => UserResource.pendingUserRequests.push({ resolve, reject }));
  }

  requirePermissionGate = ({
    submitProfessional,
    joinCompany,
    waitForApprove,
    useRestrictionCookie,
  }) => !this.isGod()// Allow for god users
  // Allow for bots, visitors that comes from search engines
  // and visitors that visits some pages for first time
      && ((!this.isAuthenticated() && (!useRestrictionCookie
                || (useRestrictionCookie && !this.isPermissionDeactivated())))
        // don't allow for not verified users
        || this.notVerified()
        // allow if generic users and doesn't require form submission or joining a company
        || (this.isGeneric() && (submitProfessional || joinCompany))
        // allow if non-professional users and doesn't require joining a company
        || (this.isNonProfessional() && joinCompany)
        // don't allow if the user has role request and waiting for approve
        // if it requires only professional user like (contact) feature
        || (this.isPendingProfessional() && waitForApprove)
      );

  isGod = () => this.isAuthenticated()
    && UserResource.user.data.is_god;

  isProfessional = () => this.isAuthenticated()
    && UserResource.user.data.user_progress === USER_PROGRESSES.PROFESSIONAL;

  isPendingProfessional = () => this.isAuthenticated()
    && UserResource.user.data.user_progress === USER_PROGRESSES.PENDING_PROFESSIONAL;

  isNonProfessional = () => this.isAuthenticated()
    && UserResource.user.data.user_progress === USER_PROGRESSES.NON_PROFESSIONAL;

  isGeneric = () => this.isAuthenticated()
    && UserResource.user.data.user_progress === USER_PROGRESSES.GENERIC;

  notVerified = () => this.isAuthenticated()
    && UserResource.user.data.user_progress === USER_PROGRESSES.NOT_VERIFIED;

  canEditProject = firmIds => this.isAuthenticated()
    && (this.isGod() || intersection(UserResource.user.data.admin_firm_ids, firmIds).length);

  canCollect = () => this.isAuthenticated() && (
    this.isNonProfessional()
    || this.isPendingProfessional()
    || this.isProfessional()
    || this.isGod()
  );

  isBrandAdmin = brandId => this.isAuthenticated()
    && (this.isGod() || (UserResource.user.data.admin_brand_ids || []).indexOf(brandId) >= 0);

  canFindSimilar = () => this.isPendingProfessional()
      || this.isProfessional()
      || this.isGod();

  canSaveImage = () => this.isNonProfessional()
      || this.isPendingProfessional()
      || this.isProfessional()
      || this.isGod();

  canContactBrand = () => this.isGeneric()
  || this.isNonProfessional()
  || this.isPendingProfessional()
  || this.isProfessional()
  || this.isGod();

  isAuthenticated = () => Boolean(UserResource.user);

  addToBrand(brandId, userIds = [], roles = {}, userEmails = [], permissions = []) {
    const endPoint = `${this.baseEndPoint}/invitations`;
    const payload = {
      brand_id: brandId,
      user_ids: userIds,
      user_emails: userEmails,
      permissions,
      roles,
    };
    return fetch(endPoint, {
      method: 'POST',
      headers,
      credentials,
      body: JSON.stringify(payload),
    }).then(response => response.json());
  }

  updateForBrand(brandId, userId, roles = {}, permissions = []) {
    const endPoint = `${this.baseEndPoint}/${userId}/settings/brand/${brandId}`;
    const payload = {
      brand_id: brandId,
      user_ids: [userId],
      permissions,
      roles,
    };
    return fetch(endPoint, {
      method: 'PUT',
      headers,
      credentials,
      body: JSON.stringify(payload),
    }).then(response => response.json());
  }

  getForBrand(brandId, forceParams = []) {
    const forceParamsQuery = forceParams.join('&');
    let endPoint = `${this.baseEndPoint}?brand_id=${brandId}`;
    if (forceParamsQuery.length) {
      endPoint = endPoint.concat(`&force=${forceParamsQuery}`);
    }
    return fetch(endPoint, {
      method: 'GET',
      headers,
      credentials,
    }).then(response => response.json());
  }

  removeForBrand(brandId, userId) {
    const endPoint = `${this.baseEndPoint}/${userId}/settings/brand/${brandId}`;
    const emptyBrandRoles = { brands: { } };
    emptyBrandRoles.brands[brandId] = [];
    const payload = {
      brand_id: brandId,
      user_ids: [userId],
      permissions: [],
      roles: emptyBrandRoles,
    };
    return fetch(endPoint, {
      method: 'PUT',
      headers,
      credentials,
      body: JSON.stringify(payload),
    }).then(response => response.json());
  }

  getInvitesForBrand(brandId) {
    const endPoint = `${this.baseEndPoint}/invitations?brand_id=${brandId}`;
    return fetch(endPoint, {
      method: 'GET',
      headers,
      credentials,
    }).then(response => response.json());
  }

  isPermissionDeactivated = () => {
    const allowedNumberOfViewsForRestrictedPages = 1;
    const restrictedViewsCountCookieName = 'restricted_views_count';
    const restrictedViewsCountCookieAgeInDays = 30;
    const restrictedViewsCountCookieAge = restrictedViewsCountCookieAgeInDays * 24 * 60 * 60 * 1000;

    const isBot = new RegExp(BOTS_USER_AGENT.join('|')).test(navigator.userAgent.toLowerCase());
    const isReferredBySearchEngine = new RegExp(SEARCH_ENGINES.join('|')).test(document.referrer.toLowerCase());
    let deactivatePermission = false;

    // check if the user was come from search engines
    // and he didn't routed using react-router (front end routing)
    if (!window.history.state && isReferredBySearchEngine) {
      deactivatePermission = true;
    } else {
      const restrictedViewsCountCookie = Cookies.get(restrictedViewsCountCookieName);
      let restrictedViewsCount = 1;

      if (restrictedViewsCountCookie) {
        restrictedViewsCount = parseInt(restrictedViewsCountCookie, 10);
        restrictedViewsCount += 1;
      }

      Cookies.set(restrictedViewsCountCookieName, restrictedViewsCount,
        { expires: restrictedViewsCountCookieAge });

      if (restrictedViewsCount <= allowedNumberOfViewsForRestrictedPages) {
        deactivatePermission = true;
      }
    }

    if (isBot) {
      deactivatePermission = true;
    }

    return deactivatePermission;
  }
}

UserResource.pendingUserRequests = [];
UserResource.user = null;
UserResource.userRejectedError = null;

export class BrandResource {
  static baseEndpoint = `${API_PREFIX}/brands`;

  static get(brandId) {
    const url = `${BrandResource.baseEndpoint}/${brandId}`;

    return fetch(url, {
      method: 'GET',
      headers,
      credentials,
    }).then(response => response.json());
  }

  static update(brandId, data) {
    const url = `${BrandResource.baseEndpoint}/${brandId}`;

    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers,
      credentials,
    }).then(response => response.json());
  }

  /**
   * Use it to know if a brand or BrandTier object has an array of tiers.
   * @param {(Object|String)} brandOrBrandTier - GraphQL like Brand, BrandTier object,
   * or plan_id string.
   * @param {(Array|String)} tiers - Array of tiers or just a single tier string.
   * Returns Boolean.
   * @example
   * hasBrandTiers(brandObj, BRAND_TIER_FREE_ID);
   * hasBrandTiers(brandObj, [BRAND_TIER_FREE_ID, BRAND_TIER_BRAND_PROFILE]);
   */
  static hasBrandTiers(brandOrBrandTier = {}, tiers = []) {
    let tiersArray = [];
    // tiers is an array
    if (Array.isArray(tiers)) {
      tiersArray = tiers;
    // tiers is a string
    } else if (typeof tiers === 'string') {
      tiersArray = [tiers];
    // tires is not an array or a string
    } else {
      return false;
    }

    // Brand is provided
    if (brandOrBrandTier.brandTier && brandOrBrandTier.brandTier.planId) {
      return tiersArray.includes(brandOrBrandTier.brandTier.planId);
    // Brand tier is provided
    } if (brandOrBrandTier.planId) {
      return tiersArray.includes(brandOrBrandTier.planId);
    // Similar brand is provided
    } if (brandOrBrandTier.brandTierId) {
      return tiersArray.includes(brandOrBrandTier.brandTierId);
    // plan_id is provided
    } if (typeof brandOrBrandTier === 'string') {
      return tiersArray.includes(brandOrBrandTier);
    }

    return false;
  }

  static isBasic(brandOrbrandTier) {
    return BrandResource.hasBrandTiers(brandOrbrandTier, BRAND_TIER_FREE_ID);
  }

  static isPremium(brandOrbrandTier) {
    return BrandResource.hasBrandTiers(brandOrbrandTier, BRAND_TIER_BRAND_PROFILE);
  }
}

export class ProductRequestResource {
  constructor() {
    this.baseEndpoint = `${API_PREFIX}/product_requests`;
  }

  get(id) {
    const url = `${this.baseEndpoint}/${id}`;
    return fetch(url, {
      method: 'GET',
      headers,
      credentials,
    }).then(response => response.json());
  }
}

export class ProductResponseResource {
  constructor() {
    this.baseEndpoint = `${API_PREFIX}/product_responses`;
  }

  get(id, options) {
    let url = `${this.baseEndpoint}/${id}`;
    if (options.queryParams) {
      url = `${url}?${queryString.stringify(options.queryParams)}`;
    }
    return fetch(url, {
      method: 'GET',
      headers,
      credentials,
    }).then(response => response.json());
  }
}

export class MediaResource {
  static changeCredit(
    miIds,
    firmId,
    newFirmName,
    target = MEDIA_CREDIT_TARGET.NO_CREDIT_ONLY,
    recaptchaToken,
  ) {
    const body = JSON.stringify({
      query: `mutation(
        $miIds: [Int]!,
        $firmId: Int,
        $newFirmName: String,
        $target: MediaCreditTarget,
        $recaptchaToken: String,
      ) {
        changeMediaCredit(input: {
          miIds: $miIds,
          firmId: $firmId,
          newFirmName: $newFirmName,
          target: $target,
          recaptchaToken: $recaptchaToken,
        }) {
          changed
          firm {
            slug
            name
          }
        }
      }`,
      variables: {
        miIds, firmId, newFirmName, target, recaptchaToken,
      },
    });

    return APIClient('', true, body)
      .then((data) => {
        if ('errors' in data) {
          Raven.captureException(data.errors);
          throw new Error(data.errors);
        }
        return data.changeMediaCredit;
      });
  }
}

export class BrandMessageResource {
  static createMessage(
    brandID,
    productID,
    topic,
    description,
  ) {
    return fetch(`${API_PREFIX}/brand-messages`, {
      method: 'POST',
      headers,
      credentials,
      body: JSON.stringify({
        brand: brandID,
        product: productID || null,
        topic,
        message: description,
      }),
    }).then(response => response.json());
  }
}

export class ProductResource {
  static getModalData(productID) {
    const url = `${API_PREFIX}/products/${productID}/modal/`;

    return fetch(url, {
      method: 'GET',
      headers,
      credentials,
    }).then(response => response.json());
  }
}

export class OfferingResource {
  constructor() {
    this.baseEndpoint = `${API_PREFIX}/offerings`;
  }

  create(data) {
    return fetch(this.baseEndpoint, {
      method: 'POST',
      headers,
      credentials,
      body: JSON.stringify(data),
    }).then(response => response.json());
  }
}

export const S3UploadPolicy = () => {
  const url = `${API_PREFIX}/upload_policy`;
  return fetch(url, {
    method: 'GET',
    headers,
    credentials: 'same-origin',
  }).then(response => response.json());
};

export function S3UploadFile(file, s3Key) {
  return S3UploadPolicy()
    .then((response) => {
      const { fields } = response;
      const { action } = response;
      const formData = new FormData();
      for (let i = 0; i < fields.length; i += 1) {
        const { name } = fields[i];
        const { value } = fields[i];
        formData.append(name, value);
      }
      formData.append('file', file, s3Key);
      return fetch(action, {
        method: 'POST',
        body: formData,
      });
    });
}

/**
 * Sorts an array of a model's attributions.
 *
 * @param {Array.<String|Number>} attrs: attribution ids
 * @param model {String}: the attribution model which must exist in ATTRIBUTIONS_MODEL
 *
 * Returns array ignored of attribution ids that that user has no permission to edit
 * Throws response object in case of server error
 *
 * Usage:
 * SortAttributions([3, 1, 2], ATTRIBUTIONS_MODEL.MEDIA_ATTRIBUTION).then(...);
 */
export const SortAttributions = (attrs, model) => {
  if (!Array.isArray(attrs) || !ATTRIBUTIONS_GLOBAL_ID[model]) {
    return null;
  }

  const formData = new FormData();
  formData.append('json', JSON.stringify({
    attributions: attrs.map(attr => `${ATTRIBUTIONS_GLOBAL_ID[model]}.${attr}`),
  }));

  return fetch('/core/attributions/sort/', {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'same-origin',
    body: new URLSearchParams(formData),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw response;
  });
};

/**
 * Sorts an array of a model's attributions by id and index.
 *
 * @param {Array.<String|Number>} attrs: attribution ids
 * @param model {String}: the attribution model which must exist in ATTRIBUTIONS_MODEL
 *
 * Returns array ignored of attribution ids that that user has no permission to edit
 * Throws response object in case of server error
 *
 * Usage:
 * SortAttributions(
 *  [{id: 3, index: 2}, {id: 1, index: 1}, {id: 2, index: 0}],
 *  ATTRIBUTIONS_MODEL.MEDIA_ATTRIBUTION,
 * ).then(...);
 */
export const SortAttributionsByIndex = (attrs, model) => {
  if (!Array.isArray(attrs) || !ATTRIBUTIONS_GLOBAL_ID[model]) {
    return null;
  }

  const formData = new FormData();
  formData.append('json', JSON.stringify({
    attributions: attrs.map((attr) => ({
      global_id: `${ATTRIBUTIONS_GLOBAL_ID[model]}.${attr.id}`,
      index: attr.index,
    })),
  }));

  return fetch('/core/attributions/sort-by-index/', {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'same-origin',
    body: new URLSearchParams(formData),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw response;
  });
};
