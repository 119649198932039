import pluralize from 'pluralize';
import xorWith from 'lodash/xorWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {
  MEDIA_QUERIES,
  GLOBAL_HEADER_ID,
  SRC_SET_METHODS,
} from './constants';
import {
  prepareImgixParams,
} from './APIClient';

// Clones a Class instance, with all methods, properties, getters & setters
export function cloneInstance(instance) {
  return Object.assign(Object.create(Object.getPrototypeOf(instance)), instance);
}

// react-notification-system settings
const baseNotificationSettings = {
  position: 'br',
  autoDismiss: 4,
  dismissible: false,
};

export const infoNotificationSettings = Object.assign({}, baseNotificationSettings, {
  level: 'info',
});

export const errorNotificationSettings = Object.assign({}, baseNotificationSettings, {
  title: '🔥 Error',
  level: 'error',
});

export const successNotificationSettings = Object.assign({}, baseNotificationSettings, {
  title: '✅ Success',
  level: 'success',
});

export const notificationStyles = {
  NotificationItem: {
    DefaultStyle: {
      backgroundColor: '#fff',
      color: 'rgba(0,0,0,0.84)',
      boxShadow: '0 0 20px 0 rgba(0,0,0,0.1)',
      borderTop: 'none',
    },
  },
  Title: {
    DefaultStyle: {
      fontSize: '13px',
      lineHeight: 1.3,
      fontWeight: 'normal',
      fontFamily: 'TeXGyreHerosRegular',
      fontStyle: 'normal',
      textTransform: 'uppercase',
    },
    success: {
      color: '#22d290',
    },
    error: {
      color: '#ff4b1f',
    },
    info: {
      color: '#859DAF',
    },
  },
  Containers: {
    DefaultStyle: {
      width: '250px',
    },
  },
};

export const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;

export const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const randomIdGenerator = () => Math.random().toFixed(5);

export const randomInRange = (min, max) => Math.floor(Math.random() * (max - min)) + min;

/*
  Styles to be consumed by `react-modal`
  See: https://github.com/reactjs/react-modal/blob/master/README.md
  We need `overflow: visible` in cases where `MediaItemInput` and `NotificationSystem`
  are sibling components.
*/
export const modalStyles = {
  content: {
    overflow: 'visible',
  },
};

/*
- Description:
  Use it to convert array of options to readable list.
- Usage:
  const listOfColors = ['White', 'Yellow', 'Blue'];
  listToReadableString(
    listOfColors,
    {
      emptyValue: 'All Colors.',
      serialComma: true,
      suffix: '.',
    }
  );
- Output:
  'White, Yellow, and Blue.'
*/
export const arrayToReadableString = (options, {
  emptyValue = '',
  prefix = '',
  suffix = '',
  textField = '',
  serialComma = false,
}) => {
  if (!options || !options.length || !Array.isArray(options)) {
    return emptyValue;
  }
  let list = null;
  let str = '';

  // Constructs a list of strings from options parameter
  switch (typeof (options[0])) {
    case ('object'):
      list = options.map(option => option[textField]);
      break;
    default:
      list = options;
  }

  // Adds a serial comma i.e. green, white, and blue.
  if (serialComma && list.length >= 3) {
    str = `${[...list.slice(0, list.length - 1)].join(', ')}, and ${list[list.length - 1]}`;
  } else if (serialComma && list.length === 2) {
    str = list.join(' and ');
  } else {
    str = list.join(', ');
  }

  return `${prefix}${str}${suffix}`;
};

/*
- Description:
  Use it to construct a GraphQL parameter from an option object that has label and value.
- Params:
  inline param: if yes: used for GraphQL string manipulating (no GraphQL variables)
    if no used for GraphQL variables
  resultType param: if string, parse the result to string: like 10 => "10"
- Usage:
  const selectedLocation = { label: 'New York', value:'new-york' };
  prepareOptionAsGqlParameter(selectedLocation, 'value', true, 'string');
- Output:
  `"new-york"`
  case 2:
  const selectedLocation = { label: 'New York', value:10 };
  prepareOptionAsGqlParameter(selectedLocation, 'value', false, 'number');
- Output:
  10
*/
export const prepareOptionAsGqlParameter = (option, value = 'value', inline = true, resultType = 'string') => {
  let result = option && option[value] ? option[value] : '';
  if (resultType === 'string') {
    result = `${result}`;
  }
  if (inline) {
    result = JSON.stringify(result);
  }
  return result || null;
};

/*
- Description:
  Use it to construct a GraphQL parameter from an array of option objects,
  that each has key and value.
- Params:
  inline param: if yes: used for GraphQL string manipulating (no GraphQL variables)
    if no used for GraphQL variables
  resultType param: if string, parse the result to string: like 10 => "10"
- Usage:
  const selectedLocations = [
    { label: 'New York', value:'new-york' },
    { label: 'Los Angeles', value:'los-angeles' },
  ];
  prepareOptionsAsGqlParameter(selectedLocations, value='value', true, 'string');
- Output:
  `["new-york","los-angeles"]`
  case 2:
  const selectedLocations = [
    { label: 'New York', value:'new-york' },
    { label: 'Los Angeles', value:'los-angeles' },
  ];
  prepareOptionsAsGqlParameter(selectedLocations, 'value', false, 'number');
- Output:
  [10]
*/
export const prepareOptionsAsGqlParameter = (options, valueProperty = 'value', inline = true, resultType = 'string') => {
  let results = options
    ? options.map(option => (resultType === 'string' ? `${option[valueProperty] || ''}` : option[valueProperty]))
    : [];
  if (inline) {
    results = JSON.stringify(results);
  }
  return results;
};

/*
- Description:
  Use it to construct react-select option object with key, value, and any custom properties.
  Custom properties can be used to get better insight about the selected value, especially
  when the select contains options that should be treated differently.
- Usage:
  const type = ['Private House', 'private-house'];
  prepareOption(type[0], type[1], {customProperty: 'customValue'});
- Output:
  {
    label: 'Private House',
    value: 'private-house',
    customProperty: 'customValue',
  }
*/
export const prepareOption = (label, value = label, custom = {}) => {
  const option = {
    label: label ? String(label) : null,
    value: value ? String(value).replace(/"/g, '\\"') : null,
  };
  if (custom) {
    Object.assign(option, custom);
  }
  return option;
};

/*
- Description:
  Use it to construct react-select array of option objects.
- Usage:
  const types = ['Private House', 'Residential'];
  prepareOptions(types);
- Output:
  [
    {
      label: 'Private House',
      value: 'Private House',
    },
    {
      label: 'Residential',
      value: 'Residential',
    },
  ]
*/
export const prepareOptions = (options = [], labelProperty, valueProperty) => (
  options.map(option => prepareOption(
    typeof option === 'string' ? option : option[labelProperty],
    typeof option === 'string' ? option : option[valueProperty],
  ))
);

/*
- Description:
  Use it to pluralize last word of an array of sentences.
  Define the sentences you want to ignore in [ignore] array.
  Define the words that you want to convert before pluralization
    in [convert] as { oldWord: newWord }
- Usage:
  const ignore ['Blue Bell'];
  const convert { Development: Developer };
  const sentances = [
    'Blue Bell',
    'Red Apple',
    'Real Estate Development',
  ];
  pluralizeLastWord(sentances, ignore, sentances);
- Output:
  [
    'Blue Bell',
    'Red Apples',
    'Real Estate Developers',
  ]
*/
export const pluralizeLastWord = (sentence = '', ignore = [], convert = {}) => {
  if (ignore.includes(sentence)) {
    return sentence;
  }
  const words = sentence.split(' ');
  const lastWord = words.pop();
  return [...words, pluralize(convert[lastWord] ? convert[lastWord] : lastWord)].join(' ');
};
export const pluralizeLastWordArray = (sentences = [], ignore = [], convert = {}) => sentences.map(
  sentence => pluralizeLastWord(sentence, ignore, convert),
);

/*
- Description:
  Use it to convert an int or a float to string with commas.
  empty string will be returned if n is invalid.
- Usage:
  numberWithCommas(1000);
  numberWithCommas(12237742.12);
- Output:
  '1,000'
  '12,237,742.12'
*/
export const numberWithCommas = n => (parseFloat(n, 10) || '').toLocaleString();

/*
- Description:
  Deep comparison between two arrays using lodash.
  Arrays can contain any values including objects.

  Return true when src and dist arraies are deeply equal, undefined otherwise.
- Usage:
  isArrayEqual([1, 2, 3], [1, 2, 3]);
  isArrayEqual(['a', 1], ['b']);
  isArrayEqual([{ id: 23 }], [{ id: 23 }]);
- Output:
  true
  undefined
  true
*/
export const isArrayEqual = (src, dist) => isEmpty(xorWith(src, dist, isEqual));

/**
 * Use it to transform array of objects into array of objects, each contain id and isActive
 * to highlight active item.
 * @param {Array} arr - array of objects
 * @param {String} idKey - id key name
 * @param {*} activeId - active id that will be highlighted
 * @returns {Array.<{id: *, isActive: boolean}>}
 * Returns new array of objects, each with id isActive
 * Returns empty array when missing arguments or id field is invalid
 * @example
 * highlightArrayActiveId([
 *  { value: 0, color: 'red' },
 *  { value: 1, color: 'blue' },
 *  { value: 2, color: 'green' },
 * ], 'value', 1)
 * // returns
 * [
 *  { id: 0 },
 *  { id: 1, isActive: true },
 *  { id: 2 },
 * ]
 */
export const highlightArrayActiveId = (arr, idKey, activeId) => {
  if (!arr || !arr.length || !idKey || !(idKey in arr[0])) {
    return [];
  }
  return arr.map(element => (
    element[idKey] === activeId
      ? { id: element[idKey], isActive: true }
      : { id: element[idKey] }
  ));
};

export function getUrlParameter(search, paramName) {
  if (!search || !paramName) {
    return null;
  }

  try {
    const param = paramName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
    const results = regex.exec(search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
  } catch (e) {
    return null;
  }
}

/**
 * To render a different wrapping element based on a condition,
 * we can use this one line functional component and determine in our component
 * when(condition) and how(wrapper) should the children be wrapped.
 * https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 *
 * @param {Boolean} condition - Use wrapper element
 * @param {Node} wrapper - wrapper element to be used if condition is true
 * @param {Node} children
 */
export const ConditionalWrapper = ({ condition, wrapper, children }) => (
  condition ? wrapper(children) : children);

/**
 * Adds a class to a DOM element.
 * @param {Element} element: DOM element that will have the new class
 * @param {String} className: string of the class name/s (separated by space) to be added
 * Returns true if successful or false if any parameter is invalid.
 */
export const addClassName = (element, className) => {
  if (!element || typeof element.className !== 'string' || typeof className !== 'string') {
    return false;
  }

  const elm = element;
  // Multiple classes
  if (elm.className.indexOf(' ') !== -1 && elm.className.indexOf(` ${className}`) === -1 && elm.className.indexOf(`${className} `) === -1) {
    elm.className += ` ${className}`;
  // Single class
  } else if (elm.className.indexOf(className) === -1) {
    elm.className = className;
  }

  return true;
};
/**
 * Removes a class from a DOM element.
 * @param {Element} element: DOM element that will have the new class
 * @param {String} className: string of the class name/s (separated by space) to be removed
 * Returns true if successful or false if any parameter is invalid.
 */
export const removeClassName = (element, className) => {
  if (!element || typeof element.className !== 'string' || typeof className !== 'string') {
    return false;
  }

  const elm = element;
  // Multiple classes
  if (elm.className.indexOf(' ') !== -1 && (elm.className.indexOf(` ${className}`) !== -1 || elm.className.indexOf(`${className} `) !== -1)) {
    elm.className = elm.className.replace(` ${className}`, '').replace(`${className} `, '');
  // Single class
  } else if (elm.className.indexOf(className) !== -1) {
    elm.className = elm.className.replace(className, '');
  }

  return true;
};

/** Architizer hlobal header DOM element */
export const globalHeaderElement = document.getElementById(GLOBAL_HEADER_ID);
/** Hide Architizer global header by adding `hide` CSS class */
export const hideGlobalHeader = () => addClassName(globalHeaderElement, 'hide');
/** Show Architizer global header by removing `hide` CSS class */
export const showGlobalHeader = () => removeClassName(globalHeaderElement, 'hide');

/**
 * Returns a string that contains a set of imgIX prepared sources to be used as img srcSet.
 * @param {String} url: Raw imgIX URL string of the media resource
 * @param {Array.<Object.<String, String|Number>>} sources: set sources based on MEDIA_SIZES
 * @param {String} method: descriptor method based on SRC_SET_METHODS
 */
export const generateSrcSet = (url, sources = [], method = SRC_SET_METHODS.WIDTH) => {
  if (!sources || !sources.length || !method) {
    return url || '';
  }

  const srcSet = [];
  sources.forEach((source) => {
    if (method === SRC_SET_METHODS.WIDTH) {
      srcSet.push(`${prepareImgixParams(url, source)} ${source.w}w`);
    } else if (method === SRC_SET_METHODS.RESOLUTION) {
      // To be implemented when needed..
    }
  });

  return srcSet.join(',');
};

/**
 * Returns a string that contains a set of src sizes based on the provided values that will be
 * matched with queries from MEDIA_QUERIES, it can set default value as well.
 * Warning: srcSet must be specified on img tags in order for this to work, you can use
 *          generateSrcSet to generate the srcSet.
 *
 * @param {Number} large: size of the src to be used on large screens
 * @param {Number} medium: size of the src to be used on medium screens
 * @param {Number} small: size of the src to be used on small screens
 * @param {Number} setDefault: if no sizes is matched, use a defaul src.
 *
 * Example:
 * generateSrcSizes({
 *  large: MEDIA_SIZES.LARGE.w,
 *  medium: MEDIA_SIZES.MEDIUM.w,
 *  small: MEDIA_SIZES.SMALL.w
 * });
 * Result: '(min-width: 64em) 1680px,(min-width: 40em) 1080px,(max-width: 39.9375em) 520px,1680px'
 */
export const generateSrcSizes = ({
  large, medium, small, setDefault = true,
}) => {
  let result = '';
  const isLarge = typeof large === 'number';
  const isMedium = typeof medium === 'number';
  const isSmall = typeof small === 'number';

  if (!isLarge && !isMedium && !isSmall) {
    return result;
  }

  if (isLarge) {
    result += `(min-width: ${MEDIA_QUERIES.large}) ${large}px`;
  }
  if (isMedium) {
    result += `${result ? ',' : ''}(min-width: ${MEDIA_QUERIES.medium}) ${medium}px`;
  }
  if (isSmall) {
    result += `${result ? ',' : ''}(max-width: ${MEDIA_QUERIES.small}) ${small}px`;
  }

  if (setDefault) {
    const defaultSize = large || medium || small;
    result += `${result ? ',' : ''}${defaultSize}px`;
  }

  return result;
};

/**
 * Updates canonical link in the document header.
 * You can pass a complete URL (href) which will be assigned to the link's href attribute, or
 * you can only pass pathname and the function will use window.location.origin to construct
 * a complete URL, when none is passed, the location.href will be used.
 *
 * @param {string} [href] - The new complete URL
 * @param {string} [pathname] - The new pathname
 *
 * Usage:
 * updateCanonicalHref({ href: 'https://architizer.com/complete-url' });  // or
 * updateCanonicalHref({ pathname: '/partial-url' });  // or
 * updateCanonicalHref();
 */
export const updateCanonicalHref = ({
  href = null,
  pathname = null,
}) => {
  const canonical = document.getElementById('canonical-link');
  if (!canonical) {
    return false;
  }

  if (href) {
    canonical.href = href;
    return true;
  } if (pathname && window.location) {
    canonical.href = `${window.location.origin}${pathname}`;
    return true;
  } if (window.location) {
    canonical.href = window.location.href;
    return true;
  }

  return false;
};

/**
 * @callback listenCallback
 * @param  {object} location - Updated history location
 * @param  {action} string - Updated history action
 */
/**
 * A global wrapper of React Router Dom History Listen that's responsible for executing
 * shared tasks across all components.
 * Tasks:
 * - Keep canonical link up to date when history is changed.
 * -
 * @param {listenCallback} [fn] - a function that will be called with original history.listen params
 * once the shared code is executed.
 *
 * Usage:
 * componentCallbackFn = (location, action) => {}
 * props.history.listen(architizerHistoryListener(componentCallbackFn))
 */
export const architizerHistoryListener = fn => (location, action) => {
  updateCanonicalHref({ pathname: location.pathname });

  if (typeof fn === 'function') {
    fn(location, action);
  }
};
