/* --- Polyfills --- */
import 'whatwg-fetch';
import 'core-js/es/map';
import 'core-js/es/set';

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import AdHolder from '../components/AdHolder';
import APIClient, { iterUnpack } from '../services/APIClient';
import SegmentClient from '../services/SegmentClient';
import Card from '../components/homepage/Card';
import {
  PUBLISHED,
  AD_UNITS as GAM_AD_UNITS,
  AD_CLIENTS,
} from '../services/constants';
import { randomIdGenerator } from '../services/utils';
import {
  SECTIONS,
  SECTION_POST_COUNT,
  getNewsletterBg,
  ANALYTICS_NAME,
} from '../components/homepage/Constants';
import {
  ANALYTICS_NAME as UNIVERSAL_MODAL_ANALYTICS_NAME,
  BRAND_EXPOSURE_SECTIONS,
  BRAND_EXPOSURE_ACTIONS,
} from '../components/universal_modal/Constants';
import GA4Client from '../services/GA4Client';

const ga4 = new GA4Client();

const segment = new SegmentClient();

const Newsletter = () => (
  <a
    className="block white bg-gunmetal-300 mb-xl hover-img-opacity-9"
    style={{ height: 340 }}
    href="https://join.architizer.com/architizer-newsletter/"
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => segment.track(ANALYTICS_NAME.HOMEPAGE_ACTION, {
      action: ANALYTICS_NAME.ACTIONS.NEWSLETTER_SUBSCRIBE_CLICKED,
    })}
  >
    <div
      className="grid-x full-height image lazyload p-xl"
      data-bg={getNewsletterBg()}
      style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <h3 className="cell fw-bold">Sign Up for the Architizer Newsletter</h3>
      <div className="cell align-self-bottom">
        <h4 className="mb-base">Architectural inspiration straight to your inbox.</h4>
        <div className="button fw-bold">Subscribe Now</div>
      </div>
    </div>
  </a>
);

const TrendingArticles = ({ posts, prepareCategories, page }) => {
  if (posts && posts.length) {
    return (
      <div className="mb-xl">
        <h6 className="mb-base tt-uc gray-400 ls-2">Trending Articles</h6>
        <ul className="m-0">
          {posts.map((post, i) => (
            <li key={post.id} className="grid-x mb-m">
              <div className="cell shrink mr-xs">
                <a
                  className="image block of-hidden bg-gunmetal-300"
                  style={{ width: 120, height: 80 }}
                  href={post.url}
                  onClick={() => segment.track(ANALYTICS_NAME.HOMEPAGE_ACTION, {
                    action: ANALYTICS_NAME.ACTIONS.TRENDING_ARTICLE_CLICKED,
                    id: post.id,
                    name: post.title,
                    position: i + 1,
                    page,
                    isNew: post.isNew,
                    isTrending: post.isTrending,
                    isRising: post.isRising,
                  })}
                >
                  {post.thumbnailSmall && (
                  <img
                    className="full-height full-width cover lazyload"
                    src={post.thumbnailSmall}
                    data-src={post.thumbnailSmall}
                    alt={post.title}
                  />
                  )}
                  <div data-bg={post.thumbnailSmall} className="full-height lazyload" />
                </a>
              </div>
              <div className="cell auto">
                {post.isNew && <i className="material-icons green-600 mr-xs cursor-help" title="New">add</i>}
                {post.isRising && <i className="material-icons red-300 mr-xs cursor-help" title="Popular">trending_up</i>}
                <a
                  className="black hover-blue"
                  href={post.url}
                  onClick={() => segment.track(ANALYTICS_NAME.HOMEPAGE_ACTION, {
                    action: ANALYTICS_NAME.ACTIONS.TRENDING_ARTICLE_CLICKED,
                    id: post.id,
                    name: post.title,
                    position: i + 1,
                    page,
                    isNew: post.isNew,
                    isTrending: post.isTrending,
                    isRising: post.isRising,
                  })}
                >
                  {post.title}
                </a>
                <small className="block gray-500">
                  {prepareCategories(post.categories, 'gray-500 hover-gray-800')}
                </small>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};
TrendingArticles.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape()),
  prepareCategories: PropTypes.func,
  page: PropTypes.number,
};
TrendingArticles.defaultProps = {
  posts: [],
  prepareCategories: () => {},
  page: null,
};

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      sections: [],
      featuredPosts: this.defaultFeaturedPost,
      postsNextPage: null,
      trendingPosts: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchPage();
  }

  defaultFeaturedPost = [...Array(3).keys()].map(() => ({}));

  defaultPostSection = {
    name: SECTIONS.POSTS,
    nodes: [...Array(7).keys()].map((_, i) => ({ id: randomIdGenerator(), isSingleHero: i === 0 })),
  };

  defaultProductSection = {
    name: SECTIONS.FEATURED_PRODUCTS,
    nodes: [...Array(6).keys()].map(() => ({ key: randomIdGenerator(), brand: {}, product: {} })),
  }

  addSection = ({
    index, section, nextPage, isLoading,
  }) => {
    const { name, nodes } = section;
    if (!name || !nodes) {
      return;
    }

    const stateUpdates = {};
    const { sections } = this.state;

    if (!Number.isNaN(index) && sections.length > index) {
      const sectionsUpdated = cloneDeep(sections);
      sectionsUpdated[index] = section;
      stateUpdates.sections = sectionsUpdated;
    } else {
      stateUpdates.sections = [...sections, section];
    }

    if (isLoading !== undefined) {
      stateUpdates.isLoading = isLoading;
    }

    if (nextPage) {
      stateUpdates.postsNextPage = nextPage;
    }

    this.setState(stateUpdates);
  }

  preparePosts = (posts) => {
    if (!isArray(posts)) {
      return posts;
    }

    const updatedPosts = cloneDeep(posts);
    // Mark hero posts
    const heroCount = updatedPosts.length - SECTION_POST_COUNT;
    if (heroCount === 1) {
      updatedPosts[0].isSingleHero = true;
    } else if (heroCount === 2) {
      updatedPosts[0].isDoubleHero = true;
      updatedPosts[1].isDoubleHero = true;
    }
    // Inject ad in a random position
    // updatedPosts.splice(randomInRange(heroCount + 1, updatedPosts.length - 1), 0, {
    //   id: Math.random(),
    //   isAd: true,
    // });

    return updatedPosts;
  }

  fetchPosts = ({ first = 8, after = 1, isSticky = false } = {}) => {
    let index = null;
    if (!isSticky) {
      index = this.state.sections.length;
      this.addSection({ section: this.defaultPostSection });
    }

    const query = `query($first: Int, $after: String, $nextPage: String, $isSticky: String, $sort: String) {
      allWpPosts(first: $first, after: $after, nextPage: $nextPage, sticky: $isSticky, sort: $sort) {
        totalCount
        nextPage
        edges {
          node {
            id: postId
            date
            elapsedTime
            url
            title
            excerpt
            thumbnailSmall
            thumbnailMedium
            thumbnailLarge
            categories {
              name
              slug
              url
            }
            isNew
            isTrending
            isRising
          }
        }
      }
    }`;
    const body = JSON.stringify({
      query,
      variables: {
        first,
        after,
        nextPage: this.state.postsNextPage,
        isSticky: isSticky ? 'require' : 'include',
        sort: isSticky ? 'modified' : 'date',
      },
    });

    APIClient('', false, body).then(r => r.json())
      .then((r) => {
        if ('errors' in r) {
          r.errors.map((error) => { throw new Error(error.message); });
        }
        return r.data;
      }).then((r) => {
        const { nextPage } = r.allWpPosts;
        let posts = iterUnpack(r).allWpPosts;

        if (isSticky) {
          this.setState({ featuredPosts: posts });
        } else {
          posts = this.preparePosts(posts);
          this.addSection({
            index,
            section: { name: SECTIONS.POSTS, nodes: posts },
            nextPage,
            isLoading: false,
          });
        }
      });
  }

  fetchTrendingPosts = ({ first = 8 } = {}) => {
    const query = `query($first: Int) {
      allWpTrendingPosts(first: $first){
        totalCount
        edges{
          node {
            id: postId
            elapsedTime
            url
            title
            thumbnailSmall
            thumbnailMedium
            thumbnailLarge
            categories {
              name
              slug
              url
            }
            isNew
            isTrending
            isRising
          }
        }
      }
    }`;
    const body = JSON.stringify({
      query,
      variables: { first },
    });

    APIClient('', true, body).then((r) => {
      this.setState({ trendingPosts: r.allWpTrendingPosts });
    });
  }

  populateProductsBadges = (brands) => {
    if (!isArray(brands)) {
      return;
    }

    const now = new Date().getTime();
    const totalUsage = brands.reduce((acc, b) => (acc + (b.products[0].projectsCount)), 0);
    const avgUsage = Math.floor(totalUsage / brands.length) || Math.max;

    brands.forEach((brand) => {
      const product = brand.products[0];
      let days;
      try {
        days = (now - new Date(product.created).getTime()) / (1000 * 3600 * 24);
      } catch (_) {
        days = Math.max;
      }
      product.isNew = days < 30;
      product.isTrending = product.projectsCount >= avgUsage;
    });
  }

  prepareProductNodes = (brands) => {
    if (!isArray(brands)) {
      return brands;
    }

    const brandArr = brands.filter(b => b.products.length);

    this.populateProductsBadges(brandArr);

    return brandArr.map((brand) => {
      const product = brand.products.length ? brand.products[0] : {};
      return {
        key: `${brand.id}-${product.id}`,
        brand: {
          src: brand.media.length ? brand.media[0].mediaItem.thumbnail : null,
          id: brand.id,
          name: brand.name,
          url: `/brands/${brand.slug}/`,
        },
        product: {
          src: product.media.length ? product.media[0].mediaItem.medium : null,
          id: product.id,
          name: product.name,
          url: `/brands/${brand.slug}/products/${brand.products[0].slug}/`,
          tags: product.tags.map(t => ({
            name: t.tag.text,
            slug: t.tag.slug,
            url: t.tag.productSearchUrl,
          })),
          isNew: product.isNew,
          isTrending: product.isTrending,
        },
      };
    });
  }

  fetchFeaturedProducts = ({ first = 6, onlyPaying = true } = {}) => {
    const { sections } = this.state;
    const index = sections.length;
    this.addSection({ section: this.defaultProductSection });

    const query = `query($first: Int, $onlyPaying: Boolean, $status: String, $orderBy: [String]) {
      allBrands(first: $first, onlyPaying: $onlyPaying, status: $status, orderBy: $orderBy) {
        edges {
          node {
            id: djangoId
            name
            slug
            media(last: 1, type: "logo") {
              ...mediaItem
            }
            products(first: 1, status: $status, selfStatus: $status, orderBy: $orderBy) {
              edges {
                node {
                  id: djangoId
                  name
                  slug
                  created
                  projectsCount
                  tags(first: 2, tag_Parent_Parent_Parent_Slug: "product-type") {
                    edges {
                      node {
                        tag {
                          text
                          slug
                          productSearchUrl
                        }
                      }
                    }
                  }
                  media(last: 1, type: "hero") {
                    ...mediaItem
                  }
                }
              }
            }
          }
        }
      }
    }
    fragment mediaItem on MediaItemAttributionNodeConnection {
      edges {
        node {
          mediaItem {
            thumbnail
            medium
          }
        }
      }
    }`;
    const body = JSON.stringify({
      query,
      variables: {
        first, onlyPaying, status: PUBLISHED, orderBy: '?',
      },
    });

    APIClient('', true, body).then((r) => {
      const nodes = this.prepareProductNodes(r.allBrands);
      this.addSection({ index, section: { name: SECTIONS.FEATURED_PRODUCTS, nodes } });

      segment.track(UNIVERSAL_MODAL_ANALYTICS_NAME.BRANDS_EXPOSURE, {
        section: BRAND_EXPOSURE_SECTIONS.HOMEPAGE,
        action: BRAND_EXPOSURE_ACTIONS.FEATURED_IMPRESSION,
        exposed_brands: nodes.map(n => n.brand.id || '').join(','),
        exposed_products: nodes.map(n => n.product.id || '').join(','),
      });
    });
  }

  fetchPage = () => {
    // Wait for current requests to finish
    if (this.state.isLoading) {
      return;
    }

    const page = this.state.page + 1;
    // Fetch featured posts
    if (page === 1) {
      this.fetchPosts({ isSticky: true });
    } else {
      // Use /feed/<page>/ to track the current page
      segment.page({
        url: `${window.location.origin}/feed/${page}/${window.location.search}`,
        path: `${window.location.pathname}feed/${page}/`,
      });
      ga4.page();
    }

    // Fetch posts
    if (page % 2 === 0) {
      this.fetchPosts({ first: 8 });
    } else {
      this.fetchPosts({ first: 7 });
    }

    // Fetch trending posts
    if (page === 1) {
      setTimeout(this.fetchTrendingPosts, 25);
    }
    // Fetch featured products every 3 pages
    if (page % 3 === 0) {
      setTimeout(this.fetchFeaturedProducts, 25);
    }

    this.setState({ page, isLoading: true });
  }

  prepareCategories = (categories, classNames) => {
    if (!categories) {
      return null;
    }
    if (!categories.length) {
      return undefined;
    }

    return categories.map((category, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${category.slug}-${i}`}>
        <a
          href={category.url}
          className={classNames}
          onClick={() => segment.track(ANALYTICS_NAME.HOMEPAGE_ACTION, {
            action: ANALYTICS_NAME.ACTIONS.CATEGORY_CLICKED,
            name: category.name,
          })}
        >
          {category.name}
        </a>
        {i + 1 < categories.length && <> • </>}
      </Fragment>
    ));
  }

  preparePostMeta = (post) => {
    if (isEmpty(post) || !post.title) {
      return null;
    }
    return (
      <>
        {post.elapsedTime}
        {post.elapsedTime && post.categories.length ? ' • ' : ''}
        {this.prepareCategories(post.categories, 'gray-500 hover-gray-800')}
      </>
    );
  }

  prepareBrandExtra = (brand, product) => (isEmpty(brand) ? null : (
    <>
      By
      {' '}
      <a
        className="gray-700 hover-gray-900"
        href={brand.url}
        onClick={() => segment.track(UNIVERSAL_MODAL_ANALYTICS_NAME.BRANDS_EXPOSURE, {
          section: BRAND_EXPOSURE_SECTIONS.HOMEPAGE_CLICK,
          action: BRAND_EXPOSURE_ACTIONS.BRAND_CLICK,
          exposed_brands: `${brand.id || ''}`,
          exposed_products: `${product.id || ''}`,
        })}
      >
        {brand.name}
      </a>
    </>
  ));

  buildFeaturedArticle = (post, i) => {
    const isLarge = this.state.featuredPosts.length > 2 && i === 0;
    return (
      <Card
        isHero
        size={isLarge ? 'large' : 'medium'}
        height={isLarge ? 451 : 210}
        src={isLarge ? post.thumbnailLarge : post.thumbnailMedium}
        classNames="mb-xl"
        link={post.url}
        category={this.prepareCategories(post.categories, 'white hover-gray-50')}
        heading={post.title}
        headingClassNames="hover-blue fw-bold"
        onClick={() => segment.track(ANALYTICS_NAME.HOMEPAGE_ACTION, {
          action: ANALYTICS_NAME.ACTIONS.ARTICLE_CLICKED,
          id: post.id,
          name: post.title,
          position: i + 1,
          page: this.state.page,
          isNew: post.isNew,
          isTrending: post.isTrending,
          isRising: post.isRising,
        })}
      />
    );
  }

  render() {
    const {
      featuredPosts, sections, trendingPosts, page,
    } = this.state;

    return (
      <div className="architizer-container">
        {/* Featured Articles */}
        <div className="grid-x grid-padding-x mt-xl">
          {/* 970x250 & 320x100 Ads */}
          <div className="cell mb-base">
            <AdHolder
              className="show-for-medium of-hidden"
              client={AD_CLIENTS.GAM}
              adUnit={GAM_AD_UNITS.HOMEPAGE_DESKTOP_TOP_BILLBOARD}
              center
            />
            <AdHolder
              className="hide-for-medium"
              client={AD_CLIENTS.GAM}
              adUnit={GAM_AD_UNITS.HOMEPAGE_MOBILE_TOP_LARGE_BANNER}
              center
            />
          </div>
          <div className={`cell ${featuredPosts.length >= 2 ? 'medium-8' : 'medium-12'}`}>
            {featuredPosts.length >= 1 ? this.buildFeaturedArticle(featuredPosts[0], 0) : ''}
          </div>
          <div className={`cell ${featuredPosts.length >= 2 ? 'medium-4' : 'medium-12'}`}>
            {featuredPosts.length >= 2 ? this.buildFeaturedArticle(featuredPosts[1], 1) : ''}
            {featuredPosts.length >= 3 ? this.buildFeaturedArticle(featuredPosts[2], 2) : ''}
          </div>
        </div>
        {/* Mobile Widgets */}
        <div className="show-for-small-only">
          {/* Newsletter */}
          <Newsletter />
          {/* Trending Articles */}
          <TrendingArticles
            posts={trendingPosts}
            prepareCategories={this.prepareCategories}
            page={page}
          />
          {/* 300x250 Ad */}
          <AdHolder
            className="block mb-base"
            client={AD_CLIENTS.GAM}
            // AP_AD_UNITS.HOMEPAGE_RIGHT_RAIL_STICKY_MEDIUM_BANNER
            adUnit={GAM_AD_UNITS.HOMEPAGE_RIGHT_RAIL_STICKY_MEDIUM_BANNER}
            center
          />
        </div>
        {/* Main Content */}
        <div className="grid-x grid-padding-x">
          {/* Articles  */}
          <div className="cell medium-8">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => this.fetchPage()}
              hasMore={Boolean(this.state.postsNextPage)}
              loader={<div className="loading-spinner-m m-auto" key={0} />}
              threshold={500}
            >
              <div className="grid-x grid-padding-x">
                {sections.map((section, i) => {
                  /* Articles Section */
                  if (section.name === SECTIONS.POSTS) {
                    return section.nodes.filter(post => !post.isAd).map(post => (
                      <div
                        key={post.id}
                        className={`cell large-4 ${post.isSingleHero ? 'large-12' : ''} ${post.isDoubleHero ? 'large-6' : ''} mb-xl`}
                      >
                        <Card
                          isHero={post.isSingleHero || post.isDoubleHero}
                        // eslint-disable-next-line no-nested-ternary
                          height={post.isSingleHero ? 340 : post.isDoubleHero ? 300 : 170}
                          size={post.isSingleHero || post.isDoubleHero ? 'medium' : 'small'}
                          src={(post.isSingleHero && post.thumbnailLarge)
                            ? post.thumbnailLarge : post.thumbnailMedium}
                          link={post.url}
                          category={post.isSingleHero || post.isDoubleHero ? this.prepareCategories(post.categories, 'tt-uc white hover-gray-50') : undefined}
                          heading={post.title || null}
                          headingClassNames="hover-blue fw-bold"
                          extra={post.isSingleHero || post.isDoubleHero
                            ? post.elapsedTime : this.preparePostMeta(post)}
                          extraClassNames={post.isSingleHero || post.isDoubleHero ? null : 'gray-500'}
                          isNew={post.isNew}
                          isTrending={post.isTrending}
                          isRising={post.isTrending ? false : post.isRising}
                          onClick={() => segment.track(ANALYTICS_NAME.HOMEPAGE_ACTION, {
                            action: ANALYTICS_NAME.ACTIONS.ARTICLE_CLICKED,
                            id: post.id,
                            name: post.title,
                            position: i + 1,
                            page,
                            isNew: post.isNew,
                            isTrending: post.isTrending,
                            isRising: post.isRising,
                          })}
                        />
                      </div>
                    ));
                  /* Trending Products Section */
                  } if (section.name === SECTIONS.FEATURED_PRODUCTS) {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`featured-products-${i}`} className="cell">
                        <div className="grid-x grid-padding-x">
                          <h5 className="cell large-8 tt-uc gray-400 ls-2 mb-base">Trending Products</h5>
                          <div className="cell large-4 mb-base text-right">
                            <a className="fs-s gray-400 hover-blue" href="/product-search/">
                              Product Catalog <i className="material-icons fs-s">arrow_forward</i>
                            </a>
                          </div>
                          {section.nodes.map(fp => (
                            <div key={fp.key} className="cell large-4 mb-xl">
                              <Card
                                size="small"
                                height={170}
                                src={fp.product.src}
                                subSrc={fp.brand.src}
                                link={fp.product.url}
                                subLink={fp.brand.url}
                                category={this.prepareCategories(fp.product.tags, 'gray-500 hover-gray-800')}
                                heading={fp.product.name || null}
                                headingClassNames="hover-blue fw-bold"
                                extra={this.prepareBrandExtra(fp.brand, fp.product)}
                                extraClassNames="gray-700"
                                isNew={fp.product.isNew}
                                isRising={fp.product.isTrending}
                                onClick={() => segment.track(
                                  UNIVERSAL_MODAL_ANALYTICS_NAME.BRANDS_EXPOSURE,
                                  {
                                    section: BRAND_EXPOSURE_SECTIONS.HOMEPAGE_CLICK,
                                    action: BRAND_EXPOSURE_ACTIONS.PRODUCT_CLICK,
                                    exposed_brands: `${fp.brand.id || ''}`,
                                    exposed_products: `${fp.product.id || ''}`,
                                  },
                                )}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  /* Ads Section */
                  } if (section.name === SECTIONS.AD) {
                    return section.nodes.map(ad => (
                      <div className="cell mb-base" key={ad.id}>
                        <AdHolder
                          className={`${ad.isDesktopOnly ? 'show-for-medium' : ''} ${ad.isMobileOnly ? 'hide-for-medium' : ''}`}
                          client={AD_CLIENTS.GAM}
                          adUnit={ad.adUnit}
                          center
                        />
                      </div>
                    ));
                  }
                  return <Fragment key="" />;
                })}
              </div>
            </InfiniteScroll>
          </div>
          {/* Right Rail */}
          <div className="cell medium-4 show-for-medium">
            {/* Newsletter */}
            <Newsletter />
            {/* 300x250 Ad */}
            <div>
              <AdHolder
                className="block mb-xl"
                client={AD_CLIENTS.GAM}
                // AP_AD_UNITS.HOMEPAGE_RIGHT_RAIL_AFTER_NEWSLETTER_MEDIUM_BANNER
                adUnit={GAM_AD_UNITS.HOMEPAGE_RIGHT_RAIL_AFTER_NEWSLETTER_MEDIUM_BANNER}
                center
              />
            </div>
            {/* Trending Articles */}
            <TrendingArticles
              posts={trendingPosts}
              prepareCategories={this.prepareCategories}
              page={page}
            />
            {/* 300x250 & 300x600 Sticky Ads */}
            <div className="right-rail-sticky-ads" style={{ position: 'sticky', top: 110 }}>
              <AdHolder
                className="block mb-xl"
                client={AD_CLIENTS.GAM}
                // AP_AD_UNITS.HOMEPAGE_RIGHT_RAIL_STICKY_MEDIUM_BANNER
                adUnit={GAM_AD_UNITS.HOMEPAGE_RIGHT_RAIL_AFTER_TRENDING_MEDIUM_BANNER}
                center
              />
              <AdHolder
                className="block"
                client={AD_CLIENTS.GAM}
                adUnit={GAM_AD_UNITS.HOMEPAGE_RIGHT_RAIL_STICKY_MEDIUM_BANNER}
                center
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReactDOM.render(
  <Homepage />,
  document.getElementById('root'),
);
