import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { UserResource } from '../services/RESTClient';
import {
  ARCHITIZER_NETWORK_CODE,
  AD_TARGETS,
  AD_MIN_REFRESH_TIME,
  AD_CLIENTS,
} from '../services/constants';
import { randomIdGenerator } from '../services/utils';

export default class AdHolder extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
    this.userResource = new UserResource();

    // Google Tag
    this.googletag = window.googletag || { cmd: [], architizer_slots: {} };
    // AdPushup
    this.adpushup = window.adpushup || { que: [] };
    this.adpushup.que = this.adpushup && this.adpushup.que ? this.adpushup.que : [];
    this.apWrapperRef = React.createRef();

    this.slot = null;
    this.adId = this.constructAdId();
    this.lastRefreshTime = new Date().getTime();
    this.refreshQueue = [];
  }

  componentDidMount() {
    const { client } = this.props;
    if (client === AD_CLIENTS.GAM) {
      this.userResource.me()
        .then(user => this.setState({ user: user.data }, this.construct))
        .catch(this.construct);
    } else if (client === AD_CLIENTS.AP) {
      this.pushAdPushup();
    }
  }

  componentWillUnmount() {
    if (this.googletag && typeof this.googletag.destroySlots === 'function') {
      this.googletag.destroySlots([this.slot]);
    }
  }

  setTargets = () => {
    const { user } = this.state;
    const targets = [];

    if (user) {
      targets.push({
        key: AD_TARGETS.MEMBER_TYPE,
        value: user.user_progress,
      });
    }

    targets.forEach((target) => {
      this.googletag.pubads().clearTargeting(target.key);
      this.googletag.pubads().setTargeting(target.key, target.value);
    });
  }

  pushAdPushup = () => {
    this.adpushup.que.push(() => {
      if (!window.isAdPushupInit) {
        this.adpushup.init();
        window.isAdPushupInit = true;
      }
    });
    this.adpushup.que.push(() => this.adpushup.triggerAd(this.props.adUnit));
  }

  // Convention is: div-gpt-ad-[now milliseconds]-[random number 0.12345]
  constructAdId = () => `div-gpt-ad-${new Date().getTime()}-${randomIdGenerator()}`

  register = () => {
    const { adUnit } = this.props;

    this.slot = this.googletag.defineSlot(
      `/${ARCHITIZER_NETWORK_CODE}/${adUnit.name}`,
      adUnit.size,
      this.adId,
    ).setCategoryExclusion(
      window.location.pathname,
    ).addService(
      this.googletag.pubads(),
    );

    this.googletag.architizer_slots[this.adId] = this.slot;

    this.googletag.pubads().enableSingleRequest();
    this.googletag.pubads().disableInitialLoad();
    this.googletag.pubads().collapseEmptyDivs(true);

    this.setTargets();

    this.googletag.enableServices();
  }

  display = () => {
    this.googletag.display(this.adId);
    this.googletag.pubads().refresh([this.slot]);
  }

  construct = () => {
    const { adUnit } = this.props;

    if (!adUnit || !adUnit.name || !adUnit.size) {
      Raven.captureMessage('AdHolder Error: AdUnit is invalid.', 'error', { extra: adUnit });
      return;
    }

    this.googletag.cmd.push(this.register);
    this.googletag.cmd.push(this.display);
  }

  /**
   * Pushes a refresh request to google tag.
   * Do not use this method directly, instead use refresh method.
   */
  pushRefresh = () => {
    const { client } = this.props;

    try {
      if (client === AD_CLIENTS.GAM) {
        this.googletag.cmd.push(() => this.googletag.pubads().refresh([this.slot]));
      } else if (client === AD_CLIENTS.AP) {
        if (this.apWrapperRef && this.apWrapperRef.current) {
          this.apWrapperRef.current.innerHTML = `<div id="${this.props.adUnit}" className="_ap_apex_ad" />`;
          this.pushAdPushup();
        }
      }
    } catch (err) {
      Raven.captureException(err);
    }
  }

  /**
   * Manage queue items one at a time while waiting the defined time before removing the item,
   * pushing a refresh, and triggering next item.
   */
  triggerRefreshQueue = () => {
    if (this.refreshQueue.length) {
      setTimeout(() => {
        this.lastRefreshTime = this.refreshQueue[0].refreshTime;
        this.refreshQueue.shift();
        this.pushRefresh();
        this.triggerRefreshQueue();
      }, this.refreshQueue[0].waitTime);
    }
  }

  /**
   * Refreshes the Ad based on a threshold and a queue system where each request will be added
   * to the queue while respecting the minimum time between requests.
   */
  refresh = () => {
    const newRefreshTime = new Date().getTime();
    const previousRefreshTime = this.refreshQueue.length
      ? this.refreshQueue[this.refreshQueue.length - 1].refreshTime || this.lastRefreshTime
      : this.lastRefreshTime;
    const elapsed = newRefreshTime - previousRefreshTime;

    // Queue has items, construct new item based on the last item's time
    if (this.refreshQueue.length) {
      this.refreshQueue.push({
        // Wait threshold before refreshing
        waitTime: AD_MIN_REFRESH_TIME,
        // Refresh time happens at last item's time + threshold
        refreshTime: previousRefreshTime + AD_MIN_REFRESH_TIME,
      });
    // Queue is empty, construct new item based on threshold
    } else {
      // Refresh request is bellow threshold, respect threshold by waiting the elapsed time
      if (elapsed < AD_MIN_REFRESH_TIME) {
        this.refreshQueue.push({
          waitTime: AD_MIN_REFRESH_TIME - elapsed,
          refreshTime: previousRefreshTime + AD_MIN_REFRESH_TIME,
        });
      // Refresh request is above thresold, do refresh immediately
      } else {
        this.refreshQueue.push({
          waitTime: 0,
          refreshTime: newRefreshTime,
        });
      }

      // Trigger queue only when it's empty, otherwise, it will trigger itself
      this.triggerRefreshQueue();
    }
  }

  defaultState = () => ({
    user: null,
  });

  render() {
    const { client } = this.props;

    if (client === AD_CLIENTS.GAM) {
      return (
        <div className={`adholder ${this.props.center ? 'text-center' : ''} ${this.props.className}`}>
          <div id={this.adId} />
        </div>
      );
    } if (client === AD_CLIENTS.AP) {
      return (
        <span ref={this.apWrapperRef} className={this.props.className}>
          <div id={this.props.adUnit} className={`_ap_apex_ad ${this.props.center ? 'm-auto' : ''}`} />
        </span>
      );
    }

    return (<></>);
  }
}

AdHolder.propTypes = {
  client: PropTypes.oneOf(Object.values(AD_CLIENTS)).isRequired,
  className: PropTypes.string,
  adUnit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.number),
        ),
      ]),
    }),
  ]),
  center: PropTypes.bool,
};

AdHolder.defaultProps = {
  className: '',
  adUnit: {
    name: null,
    size: [],
  },
  center: false,
};
