export const ALL = 'ALL';
export const NO_CREDIT_ONLY = 'NO_CREDIT_ONLY';
export const MEDIA_CREDIT_TARGET = {
  [ALL]: ALL,
  [NO_CREDIT_ONLY]: NO_CREDIT_ONLY,
};

export const PHOTO_CREDIT_VALIDATION = {
  REQUIRED: 'This field is required',
  INVALID_FIRM: 'Invalid selection. Try a different one.',
};

export const PHOTO_CREDIT_MESSAGE = {
  SUCCESS: 'Image credits has been updated successfully.',
};

export const CREDIT_FIELD_PLACEHOLDER = {
  NONE: 'Search firms by name',
  MULTIPLE: 'Multiple photographers',
};

export const ANALYTICS_NAME = {
  MEDIA_CREDITS_UPDATED: 'media credits updated',
};
