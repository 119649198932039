import Cookies from 'js-cookie';

/**
 * Returns Django CSRF Token or null if it doesn't exist.
 * It searches for the token in csrftoken cookie and in csrfmiddlewaretoken input field.
 */
export const getCSRFToken = () => {
  // ensured this is present by @ensure_csrf_token
  const csrfCookie = Cookies.get('csrftoken');
  if (csrfCookie) {
    return csrfCookie;
  }

  // present by using {% csrf_token %}
  const csrfInput = document.querySelector('input[name=csrfmiddlewaretoken]');
  if (csrfInput) {
    return csrfInput.value;
  }

  return null;
};

export const DELETE_ME = null;
