export const ANALYTICS_NAME = {
  BRAND_MESSAGE_SUBMITTED: 'brand profile message submitted',
  BRAND_CONTACT_MODAL_OPENED: 'send message modal opened',
  BRAND_TELEPHONE_BUTTON_CLICKED: 'telephone button clicked',
  BRAND_PROJECT_CARD_CLICKED: 'project card clicked',
  BRAND_PROFILE_ARTICLE_CLICKED: 'brand profile article clicked',
  BRAND_SHOW_MORE_FIRMS_CLICKED: 'Show more firms button clicked',
  BRAND_FIRM_USING_BRAND_CLICKED: 'firm using brand clicked',
  BRAND_PRODUCT_TYPE_FILTER_CHANGED: 'Product Type filter changed',
  BRAND_SHOW_MORE_PRODUCTS_CLICKED: 'Show more products button clicked',
  BRAND_PROJECT_TYPE_FILTER_CHANGED: 'Project Type filter changed',
  BRAND_SHOW_MORE_PLACEMENTS_CLICKED: 'Placements show more clicked',
  BRAND_SIMILAR_BRAND_CLICKED: 'similar brand clicked',
  BRAND_MESSAGE_SENT_VIA_INTERCOM: 'brand message sent via intercom',
  BRAND_UPGRADE_CTA: 'brand upgrade cta',
  BRAND_CLICKED_PREMIUM_CTA: 'brand premium cta click',
  BRAND_RESPONDED_TO_PREMIUM_CTA: 'brand premium cta respond',
};

export const PAGE_NAME = 'brand profile';

export const BASE_URL = 'brands';

export const PATH_REGEX = {
  SLUG: '[-\\w\\d_]+',
  ID: '\\d+',
};

const STICKY_NAV_HEIGHT = 155;
export const ANCHOR_OFFSET = -1 * STICKY_NAV_HEIGHT;
export const ANCHOR_DURATION = 500;

export const CONTACT_MODAL_HASH = '#contact-modal';
export const PHONE_MODAL_HASH = '#phone-modal';

// Brand Intercom communication traits
export const INQUIRY_BRAND_ID = 'inquiry_brand_id';
export const INQUIRY_BRAND_NAME = 'inquiry_brand_name';
export const INQUIRY_PRODUCT_ID = 'inquiry_product_id';
export const INQUIRY_PRODUCT_NAME = 'inquiry_product_name';
// Brand Intercom communication element ids
export const BRAND_INQUIRY_GENERAL_BOT_ID = 'brand-inquiry-general-bot';
export const BRAND_INQUIRY_PRODUCTS_BOT_ID = 'brand-inquiry-products-bot';
