export const SECTIONS = {
  FEATURED_POSTS: 'FEATURED_POSTS',
  POSTS: 'POSTS',
  FEATURED_PRODUCTS: 'FEATURED_PRODUCTS',
  AD: 'AD',
};

export const SECTION_POST_COUNT = 6;

export const NEWSLETTER_BGS = [
  'https://architizer-prod.imgix.net/media/mediadata/uploads/159370738557901_Exterior_corner_fog_render.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/156951208439909_ZHA_Beijing_Daxing_Int_Airport_HuftonCrow.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/15942766636833.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/159479762045105_BIG_KIS_The-Twist_Image-by-Laurian-Ghinitoiu.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/1594292830303Yazgan-Voronet_Lila_Hotel_01.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/15941843346051-146A3129.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/157417415413909_ZHA_Leeza_HuftonCrow.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/155619954392107_Koichi_Takada_Architects_Interiors_of_National_Museum_of_Qatar_Gift_Shop_photo_by_Tom_Ferguson.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/1560796747347MarineEducationCenter_1.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/1560429769872%E5%AD%98%E5%9C%A8%E5%BB%BA%E7%AD%91-%E5%BB%BA%E7%AD%91%E6%91%84%E5%BD%B14-luyun.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/1537560373065SuzhouBiennale_TVM_NAARO_25.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/1547639405573Bosjes__001.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/1558035164294View1.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',
  'https://architizer-prod.imgix.net/media/mediadata/uploads/153062978319719dc0e3b-7615-4fdb-b975-9ae04c6a57dc.jpg?q=60&auto=format,compress&cs=strip&w=500&h=465&fit=crop&exp=-7&fp-y=.5&fp-x=.5',

];
export const getNewsletterBg = () => NEWSLETTER_BGS[
  Math.floor(Math.random() * NEWSLETTER_BGS.length)];

export const ANALYTICS_NAME = {
  ACTIONS: {
    NEWSLETTER_SUBSCRIBE_CLICKED: 'newsletter subscribe clicked',
    TRENDING_ARTICLE_CLICKED: 'trending article clicked',
    CATEGORY_CLICKED: 'category clicked',
    ARTICLE_CLICKED: 'article clicked',
  },
  HOMEPAGE_ACTION: 'homepage action',
};
